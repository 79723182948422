import React, { Component } from 'react'
import Loader from "../../SubComponents/Loader/Loader"
import Agent from '../../../Configs/Agent'
import Header from '../../SubComponents/Header/Header'
import Footer from '../../SubComponents/Footer/Footer'
import HomeSlider from '../../HomeSections/Slider/Slider'
import IntroSection from '../../HomeSections/IntroSection/IntroSection'
import AboutSection from '../../HomeSections/AboutSection/AboutSection'
import CounterSection from '../../HomeSections/CounterSection/CounterSection'
import CtaSection from '../../HomeSections/CtaSection/CtaSection'
import FooterCtaSection from '../../HomeSections/FooterCtaSection/FooterCtaSection'
import TestimonialSection from '../../HomeSections/TestimonialSection/TestimonialSection'
import OurServicesSection from '../../HomeSections/OurServicesSection/OurServicesSection'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
		isApiCall: true,
		homeData:{},
		settingData: {}
	}
  }
  componentWillMount() {
    Agent.primeryRoutes.getHomeData().then((response) => {
		var resData = response.data;
	  	if(resData.status){
			console.log("api data" , resData.data);
			this.setState({ isApiCall: false, homeData: resData.data });
		}
	});
	Agent.primeryRoutes.getSettimgsData().then((response) => {
		console.log("setting data",response.data);
		var resData = response.data;
		  if(resData.status){
			this.setState({ 
				settingData: resData.data.data_row,
			 });
		}
	});
  }
  componentDidMount(){
	window.$('.clients-logo').slick({
		infinite: true,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 2000
	});
  }
  render() {
	const { isApiCall, homeData,settingData } = this.state
	if(isApiCall){
		return <div className = "loader"><div><Loader /></div></div>
	}
	if(settingData===null){
		return null
	}

    return (
    	<div>
			{/* Header Start */}
		   		<Header {...this.props}/>
			{/* Header End */}
  			<div className="main-wrapper ">
				{/* Slider Start */}
					{(homeData.data_row.banner_section_publish===1) && <HomeSlider data={homeData}/> }
				{/* Slider End */}
			
			    {/* Section Intro Start */}
					{(homeData.data_row.intro_section_publish===1) && <IntroSection color= {settingData.highlight_color} data={homeData}/> }
				{/* Section Intro END */}
				
				{/* Section OurServices Start */}
					{(homeData.data_row.ourservices_section_publish===1) && <OurServicesSection color= {settingData.highlight_color} data={homeData}/> }
			    {/* Section OurServices END */}
			    
			    {/* Section Cta Start */} 
			    	{(homeData.data_row.cta_section_publish===1) && <CtaSection color= {settingData.highlight_color} data={homeData}/> }
			    {/*  Section Cta End*/}
			    {/* Section Testimonial Start */}
					{(homeData.data_row.testimonial_section_publish===1) && <TestimonialSection color= {settingData.highlight_color} data={homeData}/> }
			    {/* Section Testimonial End */}
			    <section className="section latest-blog bg-2">
				  	{/* Section About Start */}
						{(homeData.data_row.about_section_publish===1) && <AboutSection color= {settingData.highlight_color} data={homeData}/> }
					{/* Section About End */}
					
					{/* section Counter Start */}
						{(homeData.data_row.counter_section_publish===1) && <CounterSection color= {settingData.highlight_color} data={homeData}/> }
			    	{/* section Counter End  */}
			    </section>
			    
				{/* Section FooterCta Start */}
					{(homeData.data_row.footer_cta_section_publish===1) && <FooterCtaSection color= {settingData.highlight_color} data={homeData}/> }
				{/* Section FooterCta End */}
				
				{/* footer Start */}
				<Footer {...this.props}/>
			    {/* footer End */}
  			</div>
  		</div>
    )
  }
}

export default Home;
