//Application Header
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
class FooterCtaSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  render() {
    return(
      <section className="mt-100 position-relative">
        <div className="container">
          <div className="cta-block-2 bg-gray p-5 rounded border-1">
            <div className="row justify-content-center align-items-center ">
              <div className="col-lg-7">
                <span id ="montserrat" style={{color: `${this.props.color}`}} className="text-color">{this.props.data.data_row.footer_cta_section_tagline}</span>
                <h2 id ="montserrat" className="mt-2 mb-4 mb-lg-0">{this.props.data.data_row.footer_cta_section_heading}</h2>
              </div>
              <div className="col-lg-4">
                { (this.props.data.data_row.footer_cta_section_button===1) && <NavLink id ="montserrat" to="/contact-us" style={{backgroundColor: `${this.props.color}`}} className="btn btn-main weight-900 btn-round-full float-lg-right ">{this.props.data.data_row.footer_cta_section_btntxt}</NavLink> }
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default FooterCtaSection;
