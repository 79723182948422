//Application Header
import React, { Component } from 'react'
import { Link, NavLink  } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";
class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
    AOS.init({
      // initialise with other settings
      duration : 2000
    });
  }
  render() {
    return(
      <section data-aos="zoom-down" className="page-title bg-1 underline-main" style={{background: `url("${this.props.bannerImg}") 50% 50%`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                {/* <span className="text-white">About Us</span> */}
                <h1 data-aos = "fade-right" className="text-capitalize mb-4 text-lg">{this.props.title}</h1>
                {/* <ul className="list-inline">
                  <li className="list-inline-item"><a href="index.html" className="text-white">Home</a></li>
                  <li className="list-inline-item"><span className="text-white">/</span></li>
                  <li className="list-inline-item"><a href="#" className="text-white-50">About Us</a></li>
                </ul> */}
                {/* <div style = {{backgroundColor:`${this.props.color}`}} className = "center-align color-underline"></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default Banner;
