import React, { Component } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Header from '../../SubComponents/Header/Header'
import Banner from '../../SubComponents/Banner/Banner'
import Footer from '../../SubComponents/Footer/Footer'
import Agent from '../../../Configs/Agent'
import Helper from '../../../Configs/Helper'
import Loader from "../../SubComponents/Loader/Loader";
import ReCAPTCHA from "react-google-recaptcha";
class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg_sending: false,
      isApiCall: true,
      dataObj: {},
      name: "",
      email: "",
      phone: "",
      company_name: "",
      country: "",
      budget: "",
      message: "",
      alertMsg: "",
      formFieldsValidate: { name: true,email: true,phone: true, country: true,budget: true,message: true},
      settingData : {}
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onReCAPTCHAChange = this.onReCAPTCHAChange.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }
  async componentWillMount() {
    await Agent.primeryRoutes.getContactUsData().then((response) => {
      var resData = response.data;
      if(resData.status){
        this.setState({ dataObj: resData.data, isApiCall: false });
      }
    });
    AOS.init({
      // initialise with other settings
      duration : 2000
    });
  }
  componentDidMount(){
    Agent.primeryRoutes.getSettimgsData().then((response) => {
      console.log("setting data",response.data);
      var resData = response.data;
        if(resData.status){
        this.setState({ 
          settingData: resData.data.data_row,
         });
      }
    });
  }
  onChangeHandler(event){
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value, alertMsg: '' });
  }
  onReCAPTCHAChange(value) {
    console.log("Captcha value:", value);
  }
  async onSubmitHandler(event){
    let isError=true;
    const formFields = { name: true,email: true,phone: true, country: true,budget: true,message: true};
    if(this.state.name===''){
      formFields.name = false;
    } else if(this.state.email===''){
      formFields.email = false;
    } else if(this.state.phone===''){
      formFields.phone = false;
    } else if(this.state.country===''){
      formFields.country = false;
    } else if(this.state.message===''){
      formFields.message = false;
    } else {
      isError = false;
    }
    if(isError){
      this.setState({ formFieldsValidate: formFields});
      return;
    } else {
      this.setState({ formFieldsValidate: formFields, alertMsg:'', msg_sending: true });
    }

    let postData = {
      name: this.state.name,
      email:  this.state.email,
      phone: this.state.phone,
      country: this.state.country,
      company_name: this.state.company_name,
      budget: this.state.budget,
      message: this.state.message,
      
    };
    await Agent.primeryRoutes.saveContactMessage(postData).then((response) => {
       console.log(response);
      const resData = response.data;
      if(resData){
        this.setState({ alertMsg: resData.data.msg,  name: "",
        email: "",
        phone: "",
        company_name: "",
        country: "",
        budget: "",
        message: "", });
      }
      this.setState({ msg_sending: false });
    
    });

  }

  render() {
    const { isApiCall, dataObj } = this.state
    if(isApiCall){
      return null
    }
    return  (
      <div>
			{/* Header Start */}
		   		<Header {...this.props}/>
			{/* Header End */}
      <div className="main-wrapper ">
      <Banner page="contact_us" title="Get In Touch" bannerImg={dataObj.data_row.banner_image}/>
      {/* contact form start */}
      
      <section className="contact-form-wrap section border-bottom pb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <form data-aos = "fade-right" id ="montserrat" id="contact-form" className="contact__form">
                {/* form message */}
                <div className="row">
                  <div className="col-12">
                    { (this.state.alertMsg!=='') && <div className="alert alert-success contact__msg" role="alert">
                     {this.state.alertMsg}
                    </div>
                    }
                  </div>
                </div>
                {/* end message */}
                <span style = {{color: `${this.state.settingData.highlight_color}`}}  className="text-color">Send a message</span>
                <h3 id ="montserrat"   className="text-md mb-4">Contact Form</h3>
                <div id ="montserrat" className="form-group">
                  <input name="name" type="text" value={this.state.name} className={`form-control ${(!this.state.formFieldsValidate.name) ? 'errorField' : '' }`} placeholder="Your Name *" onChange={this.onChangeHandler}/>
                </div>
                <div  id ="montserrat" className="form-group">
                  <input name="company_name" value={this.state.company_name} type="text" className="form-control" placeholder="Company Name *" onChange={this.onChangeHandler}/>
                </div>
                <div  id ="montserrat" className="form-group">
                  <input name="email" type="email" value={this.state.email} className={`form-control ${(!this.state.formFieldsValidate.email) ? 'errorField' : '' }`} placeholder="Email Address *" onChange={this.onChangeHandler}/>
                </div>
                <div id ="montserrat" className="form-group">
                  <input name="phone" type="text" value={this.state.phone} className={`form-control ${(!this.state.formFieldsValidate.phone) ? 'errorField' : '' }`} placeholder="Phone Number *" onChange={this.onChangeHandler}/>
                </div>
               
                <div  id ="montserrat" className="form-group">
                  <select id ="montserrat" name="budget" value={this.state.budget} className="form-control form-padding" onChange={this.onChangeHandler}>
                    <option value="">Select Budget</option>
                    <option value="Less than $10,000">Less than $10,000</option>
                    <option value="$10,000 - $25,000">$10,000 - $25,000</option>
                    <option value="$25,000 - $50,000">$25,000 - $50,000</option>
                    <option value="$50,000 - $100,000">$50,000 - $100,000</option>
                    <option value="More than $100,000">More than $100,000 </option>
                  </select>
                </div>
                <div   className="form-group">
                  <select id ="montserrat" name="country" value={this.state.country} className={`form-control ${(!this.state.formFieldsValidate.country) ? 'errorField' : '' }`} onChange={this.onChangeHandler}>
                    <option value="">Select Country *</option>
                    <option value="USA">United States of America (USA)</option>
                    <option value="UK">United Kingdom (UK)</option>
                    <option value="UAE">United Arab Emirates (UAE)</option>
                    <option value="Australia">Australia</option>
                    <option value="Canada">Canada</option>
                    <option value="India">India</option>
                    <optgroup label="-----------------------------------------------------------"></optgroup>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="AmericanSamoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="AntiguaandBarbuda">Antigua and Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahama">Bahama</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="BosniaandHerzegovina">Bosnia and Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="BouvetIsland">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="BritishVirginIslands">British Virgin Islands</option>
                    <option value="BruneiDarussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="BurkinaFaso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="CapeVerde">Cape Verde</option>
                    <option value="CaymanIslands">Cayman Islands</option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="ChristmasIsland">Christmas Island</option>
                    <option value="Cocos(Keeling) Islands">Cocos(Keeling) Islands</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="CookIslands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="CzechRepublic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="DominicanRepublic">Dominican Republic</option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="EquatorialGuinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Honduras">Honduras</option>
                    <option value="HongKong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Iran">Iran</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea">Korea</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia">Micronesia</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Monserrat">Monserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Nambia">Nambia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                    <option value="Northwest Territories">Northwest Territories</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Romania">Romania</option>
                    <option value="Russian Federation">Russian Federation</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="SierraLeone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="St. Helena">St. Helena</option>
                    <option value="St.Kitts and Nevis">St.Kitts and Nevis</option>
                    <option value="St.Pierre and Miquelon">St.Pierre and Miquelon</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="UAE">United Arab Emirates (UAE)</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="UK">United Kingdom (UK)</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="USA">United States of America (USA)</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="VietNam">VietNam</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Yugoslavia">Yugoslavia</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                </div>
                <div  id ="montserrat" className="form-group-2 mb-4">
                  <textarea name="message" value={this.state.message} className={`form-control ${(!this.state.formFieldsValidate.message) ? 'errorField' : '' }`} rows={4} placeholder="Your Message *" defaultValue={""} onChange={this.onChangeHandler}/>
                </div>
                <div   id ="montserrat" className="form-group">
                  <ReCAPTCHA
                    sitekey="6LcTBXUUAAAAAKn_tqIGkKX8ZvfIeyVDXzvlu2cR"
                    onChange={this.onReCAPTCHAChange}
                  />
                </div>
                <div id ="montserrat" className="g-recaptcha" data-sitekey="" data-callback="enableBtn"></div>
                <button id ="montserrat" style = {{backgroundColor: `${this.state.settingData.highlight_color}`}} className="btn weight-900 btn-main" name="submit" type="button" onClick={this.onSubmitHandler}>{ this.state.msg_sending ? 'Please Wait ...' : 'Send a message' }</button>
              </form>
            </div>
            <div data-aos = "fade-left"  className="col-lg-6 col-sm-12">
              <div className="contact-content pl-lg-5 mt-5 mt-lg-0">
                <span id ="montserrat" className="text-muted">{dataObj.data_row.tagline}</span>
                <h3 id ="montserrat" className="mb-5 mt-2">{dataObj.data_row.heading}</h3>
                <OfcList datalist={dataObj.offices}/>
               
                {/* <ul className="social-icons list-inline mt-5">
                  { (dataObj.data_row.facebook_link!=='') && <li className="list-inline-item"><a href={dataObj.data_row.facebook_link}><i className="fab fa-facebook-f" /></a></li> }
                  { (dataObj.data_row.twitter_link!=='') && <li className="list-inline-item"><a href={dataObj.data_row.twitter_link}><i className="fab fa-twitter" /></a></li> }
                  { (dataObj.data_row.linkedin_link!=='') && <li className="list-inline-item"><a href={dataObj.data_row.linkedin_link}><i className="fab fa-linkedin-in" /></a></li> }
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>
        {/* footer Start */}
				<Footer {...this.props}/>
			    {/* footer End */}
      </div>
      </div>
    
    )
  }
}
function OfcList(props){
  if(Helper.objLength(props.datalist)){
    var list = props.datalist.map(function(v,k){
      if(v.status===1){
        return (
          <>
          <h4  id ="montserrat" className="text-muted black">{v.country}</h4>
          <ul id ="montserrat" className="address-block list-unstyled">
            { (v.address!=='' && v.address!==null) && <li><i className="ti-direction mr-3" />{v.address}</li> }
            { (v.email!=='' && v.email!==null) && <li> <i className="ti-email mr-3" />Email: {v.email}</li>}
            { (v.phone!=='' && v.phone!==null) && <li><i className="ti-mobile mr-3" />Phone: {v.phone}</li>}
          </ul>
          </>
        );
      }
    });
  }

  return <>{list}</>;
}
export default ContactUs;
