//Application Header
import React, { Component } from 'react'
import Helper from '../../../Configs/Helper'
import aos from "aos";
class CounterSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  render() {
    return(
      <section className="section counter sect-counter">
			      <div className="container">
              <CountersPopulate headColor = {this.props.color} datalist={this.props.data.data_row.counter_section_json}/>
			      </div>
			    </section>
    )
  }
}
function CountersPopulate(props){
  let data = JSON.parse(props.datalist);
  if(Helper.objLength(data)){
    var list = data.map(function(v,k){
      return(<div key={k} className="col-lg-3 col-md-6 col-sm-6">
      <div className="counter-item text-center mb-5 mb-lg-0">
      <i style = {{color: `${props.headColor}`}} className="ti-check color-one text-md" />
        <h3 data-aos="fade-up" className="wow fadeInUp" data-wow-delay=".2s" id ="montserrat" className="mb-0 txt-color-white weight-500"><span id ="montserrat" className="counter-stat font-weight-bold">{v.number}</span> {v.prefix}</h3>
        <p id ="montserrat" className="text-muted txt-color-gray">{v.title}</p>
      </div>
    </div>);
    });
  }
  return <div id ="montserrat" className="row">{list} </div>
}
export default CounterSection;
