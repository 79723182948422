import React, { Component } from 'react';
import {  Switch, Route, Redirect  } from 'react-router-dom';
import Home from './Views/Components/Home/Home';
import AboutUs from './Views/Components/AboutUs/AboutUs';
import Services from './Views/Components/Services/Services';
import Portfolio from './Views/Components/Portfolio/Portfolio';
import ContactUs from './Views/Components/ContactUs/ContactUs';
import TermConditions from './Views/Components/TermsCondtions/TermsCondtions';
import PrivacyPolicy from './Views/Components/PrivacyPolicy/PrivacyPolicy';
import MemberProfile from './Views/Components/MemberProfile/MemberProfile';
import {CSSTransition} from "react-transition-group"


//React Routing & Navigation
class Routes extends Component {
  render() {
    return (
      <div className="app">        
        <div className="app-body">
          <main className="main">
          <Switch>
                <Route path="/home" name="Home" component={Home}/>
                <Route path="/about-us" name="About Us" component={AboutUs}/>
                <Route path="/services" name="Services" component={() => <Services {...this.props}/>}/>
                <Route path="/portfolio" name="Portfolio" component={() => <Portfolio {...this.props}/>}/>
                <Route path="/contact-us" name="Contact Us" component={() => <ContactUs {...this.props}/>}/>
                <Route path="/terms" name="Contact Us" component={() => <TermConditions {...this.props}/>}/>
                <Route path="/privacy" name="Contact Us" component={() => <PrivacyPolicy {...this.props}/>}/>
                <Route path="/profile/:id" name="Contact Us" component={MemberProfile}/>
                <Redirect from="/" to='/home'/>
          </Switch>
          </main>
        </div>
      </div>
    );
  }
}
export default Routes;
