import React, { Component } from 'react'
import Helper from '../../../Configs/Helper'
import AOS from "aos";
import "aos/dist/aos.css";
class GeneralSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currYear: new Date().getFullYear()
		}
	}
	componentDidMount(){
		AOS.init({
			// initialise with other settings
			duration : 2000
		  });
	}
	render() {
		const { screen, dataRow } = this.props
		if (screen === 'footer') {
			return <div className="col-lg-3 col-md-5 col-sm-6">
				<div   className="widget">
					<div id ="montserrat" className="logo mb-4">
						{dataRow !== null ? Helper.companyName(dataRow.company_name, dataRow.base_color, dataRow.highlight_color) : ''}
					</div>
					<ul id ="montserrat" className="list-unstyled footer-menu lh-35">
						<li><a id ="montserrat" href={`mailto:${dataRow.email}`}><h5 id ="montserrat"  style = {{color:`${this.props.color}`}} className="text-color">{dataRow !== null ? dataRow.email : ''}</h5></a></li>
						<li><h6 id ="montserrat"><a id ="montserrat"  href={`tel:${dataRow.mobile}`}>{dataRow !== null ? dataRow.mobile : ''}</a></h6></li>
					</ul>
				</div>
			</div>
		}
		if (screen === 'bottom_footer') {
			return <div className="footer-btm pt-4">
				<div className="row">
					<div className="col-lg-6 copyright-txt-elem">
						<div id ="montserrat" className="copyright">
							{/* {dataRow !== null ? dataRow.copy_ryt_msg : ''}  */}
				All rights reserved &copy; 2014-{this.state.currYear} <span style = {{color:`${this.props.color}`}}>{dataRow !== null ? dataRow.company_name : ''}</span>
						</div>
					</div>
					<div className="col-lg-6 text-center text-lg-right">
						{dataRow !== null && <ul id ="montserrat" className="list-inline footer-socials">
							{(dataRow.facebook_link !== '') && <li className="list-inline-item"><a href={dataRow.facebook_link}><i className="fab fa-facebook-f" /></a></li>}
							{(dataRow.twitter_link !== '') && <li className="list-inline-item"><a href={dataRow.twitter_link}><i className="fab fa-twitter" /></a></li>}
							{(dataRow.linkedin_link !== '') && <li className="list-inline-item"><a href={dataRow.linkedin_link}><i className="fab fa-linkedin-in" /></a></li>}
						</ul>
						}
					</div>
				</div>
			</div>
		}
		return null;
	}
}
export default GeneralSettings;
