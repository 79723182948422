//Application Header
import React, { Component } from 'react'
import Helper from '../../../Configs/Helper'
import { NavLink } from 'react-router-dom'
class OurServicesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  render() {
    return(
      <section className="section team">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 text-center">
            <div className="section-title">
              <span style = {{color:`${this.props.color}`}} className="h6 text-color">{this.props.data.data_row.ourteam_section_tagline}</span>
              <h2 className="mt-3 content-title">{this.props.data.data_row.ourteam_section_heading}</h2>
            </div>
          </div>
        </div>
        <OurTeamList  datalist={this.props.data.our_team}/>
      </div>
    </section>
    )
  }
}
function OurTeamList(props){  
  if(Helper.objLength(props.datalist)){
    var list = props.datalist.map(function(v,k){
      return(<div key={k} className="col-lg-4 col-md-6 col-sm-6">
      <div className="team-item-wrap">
        <div className="team-item position-relative">
          <img src={v.pic}  alt={v.name} className="img-fluid w-100" />
          <NavLink to={`/profile/${v.id}`}>
          <div className="team-img-hover">
            <h5 className="text-color">View Profile</h5>
            {/* <ul className="team-social list-inline">
              <li className="list-inline-item">
                <a href={v.facebook_link} className="facebook"><i className="fab fa-facebook-f" aria-hidden="true" /></a>
              </li>
              <li className="list-inline-item">
                <a href={v.twitter_link} className="twitter"><i className="fab fa-twitter" aria-hidden="true" /></a>
              </li>
              <li className="list-inline-item">
                <a href={v.instagram_link} className="instagram"><i className="fab fa-instagram" aria-hidden="true" /></a>
              </li>
              <li className="list-inline-item">
                <a href={v.linkedin_link} className="linkedin"><i className="fab fa-linkedin-in" aria-hidden="true" /></a>
              </li>
            </ul> */}
          </div>
            </NavLink>
        </div>
        <div className="team-item-content">
          <h4 className="mt-3 mb-0 text-capitalize">{v.name}</h4>
          <p>{v.position}</p>
        </div>
      </div>
    </div>);
    });
  }
  return <div className="row justify-content-center">{list} </div>
}
export default OurServicesSection;
