//Application Header
import React, { Component } from 'react'
import Helper from '../../../Configs/Helper'
import { NavLink } from 'react-router-dom'
import $ from 'jquery';
import AOS from "aos";
import "aos/dist/aos.css";
class TestimonialSection extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {
    window.$('.testimonial-wrap').slick({
      slidesToShow: 2,
      slidesToScroll: 2,
      infinite: true,
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }, {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }

      ]
    });
    AOS.init({
      // initialise with other settings
      duration: 2000
    });
  }
  render() {
    return (
      <section className="section testimonial border-top border-bottom">

        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="section-title">
                <span id ="montserrat" data-aos="fade-right" style={{ color: `${this.props.color}` }} className="h6 text-color">{this.props.data.home_data_row.testimonial_section_tagline}</span>
                <h2 id ="montserrat" data-aos="fade-left" className="mt-3 content-title">{this.props.data.home_data_row.testimonial_section_heading}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <TestimonialsPopulate color={this.props.color} datalist={this.props.data.testimonials_rows} />
        </div>
      </section>
    )
  }
}
function TestimonialsPopulate(props) {

  if (Helper.objLength(props.datalist)) {
    var list = props.datalist.map(function (v, k) {
      return (<div key={k} className="testimonial-item position-relative">
        <i style={{ color: `${props.color}` }} className="ti-quote-left text-color" />
        <div className="testimonial-item-content">

          <div data-aos="fade-left"className="testimonial-author">
            <div className="client_img_elem">
              <img className="img-responsive" src={v.client_image} />
            </div>
            <div className="col-sm-4 client_info_elem">
              <h5 id ="montserrat" className="mb-0 text-capitalize">{v.client_name}</h5>
              <p id ="montserrat">{v.client_position}</p>
            </div>
          </div>
          <p data-aos="fade-left" id ="montserrat" className="testimonial-text">{v.client_comment}</p>
        </div>
      </div>);
    });
  }
  return <div id ="montserrat" className="row testimonial-wrap">{list} </div>
}
export default TestimonialSection;
