//Application Header
import React, { Component } from 'react'
import Agent from '../../../Configs/Agent'
import { NavLink } from 'react-router-dom'
import Helper from '../../../Configs/Helper'
import ImageGallery from 'react-image-gallery';
//import "react-image-gallery/styles/css/image-gallery.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AOS from "aos";
import "aos/dist/aos.css";

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headColor: {}
    }
  }
  componentDidMount() {
    Agent.primeryRoutes.getSettimgsData().then((response) => {
      console.log("setting data", response.data);
      var resData = response.data;
      if (resData.status) {
        this.setState({
          headColor: resData.data.data_row,
        });
      }
    });
    AOS.init({
      // initialise with other settings
      duration : 2000
    });
  }
  render() {
    // const sliderData = [
    //  {
    //     type: 'video',
    //     media: 'https://www.youtube.com/embed/n0F6hSpxaFc',
    //     description: '',
    //   },
    //   {
    //     type: 'image',
    //     media: 'assets/images/bg/home-1.jpg',
    //     description: '',
    //   },
    // ];
    const { banner_images, data_row, settings } = this.props.data;
    const link = banner_images && banner_images.map(item => { return item.image }).join("")
    if (this.state.headColor === null) {
      return null
    }
    return (
      <section className=""><MediaList color={this.state.headColor.highlight_color} datalist={banner_images} link={link} data_row={data_row} settings={settings} /></section>
    )
  }
}
function MediaList(props) {
  //let data = JSON.parse(props.datalist);
  let data = (props.datalist);
  if (Helper.objLength(data)) {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    let height = window.screen.height;
    if (isMobile) {
      height = 350;
    } else {
      height = window.screen.height - 135;
    }
    var list = [];
    list = data.map(function (v, k) {
      let htmlTag = '';
      if (v.type !== undefined && v.type === 'video') {
        htmlTag = <iframe width="560" height="415" src={v.media} />;
      } else {
        htmlTag = <img style={{ height: `${height}px` }} src={v.image} />;
      }
      return (<div key={k} className="sliderElem">{htmlTag}</div>);
    });
  }
  const { slider_interval, slider_transition_time, slider_stop_on_hover } = props.settings;
  return (
    <div data-aos="zoom-down" style={{ background: `url("${props.link}") 50% 50%`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}} className="front-banner">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-sm-12 newdiv">
            <div className = "newdiv">
              <h1  data-aos={"fade-left"} className="wow fadeInUp" data-animation="fadeInUp" id ="montserrat" id = "ideas">{props.data_row.banner_section_tagline}</h1>
              {/* <div style={{ backgroundColor: `${props.color}` }} id="top-line"></div> */}
              <span data-aos="fade-up" id ="montserrat" id = "sub-heading">{props.data_row.banner_section_heading}</span>
              {props.data_row.banner_section_button && (<NavLink data-aos="fade-up" id ="montserrat" style={{ backgroundColor: `${props.color}`, color: 'white' }} to="/contact-us" className="btn head-btn weight-900 btn-round-full">{props.data_row.banner_section_btntxt}</NavLink>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Slider;
