//Application Header
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Helper from '../../../Configs/Helper'
import AOS from "aos";
import "aos/dist/aos.css";
class AboutSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  componentDidMount(){
    AOS.init({
      // initialise with other settings
      duration : 2000
    });
  }
  render() {
   return(
     <>
    <section className="section about-2 position-relative">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6" >
          <div data-aos="fade-right" className="about-item pr-3 mb-5 mb-lg-0">
            <span id ="montserrat"  style = {{color: `${this.props.color}`}} className="h6">{this.props.data.data_row.about_section_tagline}</span>
            <h2 id ="montserrat"  className="mt-3 mb-4 position-relative content-title">{this.props.data.data_row.about_section_heading}</h2>
            <p id ="montserrat" className="mb-5">{this.props.data.data_row.about_section_desc}</p>
            { (this.props.data.data_row.about_section_button===1) && <NavLink id ="montserrat" to="/contact-us" style = {{backgroundColor: `${this.props.color}`,color:'white'}}  className="btn weight-900 btn-round-full">{this.props.data.data_row.about_section_btntxt}</NavLink> }
          </div>
        </div>
        <div data-aos="fade-left" className="col-lg-6 col-md-6">
          <div className="about-item-img">
            <img src={this.props.data.data_row.about_section_image} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="about-info section">
  <div className="container">
    <FeaturesPopulate color = {this.props.color} features={this.props.data.data_row.about_section_features}/>
  </div>
</section>
</>
    )
  }
}

function FeaturesPopulate(props){
  let features = JSON.parse(props.features);
  if(Helper.objLength(features)){
    var fList = features.map(function(v,k){
      return(<div key={k} className="col-lg-4 col-md-6 col-sm-6">
      <div data-aos = "zoom-in" className="about-info-item mb-4 mb-lg-0"> 
        <h3 id ="montserrat"  className="mb-3"><span style = {{color:`${props.color}`}} className="mr-2 text-md">{k+1}.</span>{v.title}</h3>
        <p id ="montserrat">{v.short_desc}</p>
      </div>
    </div>);
    });
  }
  return <div id ="montserrat" className="row justify-content-center">{fList} </div>
}
export default AboutSection;
