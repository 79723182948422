//Application Header
import React, { Component } from 'react'
import Helper from '../../../Configs/Helper'
import AOS from "aos";
import "aos/dist/aos.css";
class CounterSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  componentDidMount(){
    AOS.init({
      // initialise with other settings
      duration : 2000
    });
  }
  render() {
    return(
      <section className="section counter bg-counter" style={{paddingBottom: "52px"}}>
          <div className="container">
          <CountersPopulate tag = {this.props.color} datalist={this.props.data.home_data_row.counter_section_json}/>
          </div>
        </section>
    )
  }
}
function CountersPopulate(props){
  let data = JSON.parse(props.datalist);
  if(Helper.objLength(data)){
    var list = data.map(function(v,k){
      return (
        <div key={k} className="col-lg-3 col-md-6 col-sm-6">
          <div data-aos = "fade-up" className="counter-item text-center mb-5 mb-lg-0">
            <i style = {{color: `${props.tag}`}}  className="ti-check color-one text-md" />
            <h3 id ="montserrat" className="mt-2 mb-0 text-white"><span className="counter-stat font-weight-bold">{v.number}</span> {v.prefix}</h3>
            <p id ="montserrat" className="text-white-50">{v.title}</p>
          </div>
        </div>
      )
    });
  }
  return <div id ="montserrat" className="row">{list} </div>
}
export default CounterSection;
