//Application Header
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";
class AboutSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  componentDidMount(){
    AOS.init({
      // initialise with other settings
      duration : 2000
    });
  }
  render() {
   return(
      <section className="section about position-relative sect-about" style={{paddingBottom: "0px"}}>
      {/* <div className="bg-about" style={{backgroundRepeat: 'no-repeat',backgroundImage: "url(" + this.props.data.data_row.about_section_image + ")"}}></div> */}
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="about-item ">
              <span data-aos="fade-down" data-aos-duration="5000" data-de id ="montserrat" style={{color: `${this.props.color}`}} className="h6">{this.props.data.data_row.about_section_tagline}</span>
              <h2 data-aos="fade-right" id ="montserrat" className="mt-3 mb-4 position-relative content-title txt-color-white">{this.props.data.data_row.about_section_heading}</h2>
              <div data-aos="fade-right" className="about-content">
                <h4 id ="montserrat" className="mb-3 position-relative txt-color-white">{this.props.data.data_row.about_section_title}</h4>
                <p id ="montserrat" className="mb-5 txt-color-white">{this.props.data.data_row.about_section_shortdesc}</p>
                { (this.props.data.data_row.about_section_button===1) && <NavLink to="/contact-us" id ="montserrat" className="btn weight-900 btn-round-full" style={{'marginBottom': '30px','backgroundColor':`${this.props.color}`,color:"white"}}>{this.props.data.data_row.banner_section_btntxt}</NavLink> }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
  }
}
export default AboutSection;
