//This file used to make API calls at a single place, Axios is a library to make full featured API calls.
import axios from 'axios';
import Helper from './Helper';
//Axios initialization and Header Init
axios.defaults.baseURL = Helper.baseApiPath;
//Request Interceptor
  axios.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

//Response Interceptor
axios.interceptors.response.use(function (response) {
  return response;
},function (error) {
  console.log(error,'error');
  //alert(error.response.status);
  // if(error.response.status===401){
  //   window.location='/401';    
  // }
  // return Promise.reject(error.response);
  return error.response;
});

//All Types of calls are triggered from here
const requests = {
  del: url =>
    axios.delete(`${axios.defaults.baseURL}${url}`),
  get: url =>
    axios.get(`${axios.defaults.baseURL}${url}`),
  put: (url, body) =>
    axios.put(`${axios.defaults.baseURL}${url}`, JSON.stringify(body)),
  post: (url, body) =>
    axios.post(`${axios.defaults.baseURL}${url}`, (body)),
  postWithFile: (url, body) =>
    axios.post(`${axios.defaults.baseURL}${url}`,body ),
};

//Followings are customs sets of functions, each are the set of an API with data & URL
//Common Routes
const primeryRoutes = {
  getSettimgsData: () => requests.get('settings'),
  getHomeData: () => requests.get('home-data'),
  getServicesData: () => requests.get('services-data'),
  getAboutUsData: () => requests.get('aboutus-data'),
  getPortfolioData: () => requests.get('portfolio-data'),
  getContactUsData: () => requests.get('contactus-data'),
  saveContactMessage: (data) => requests.post('save-contactform', data),
  getMemberProfile: (id) => requests.get('get-member-profile/'+id),
};

export default {
  primeryRoutes
}
