import React, { Component } from "react";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Transition } from 'react-transition-group';
export default class Spinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true
    };
    this.show = this.show.bind(this);
  }
  componentDidMount() {
    //this.props.onRef(this);
  }
  show(status) {
    this.setState({
      loader: status
    });
  }
  render() {
    //return null;
    return (
      <>
        <Transition in={true} timeout={500}>
          {/* <div className = "loader-text">
            <h4>Ideas turns into reality...</h4>
          </div> */}
          <Loader
            visible={this.state.loader}
            type="ThreeDots"
            color="#f75757"
            height={100}
            width={100}
            //timeout={10000} //3 secs
            className="site_loader"
          />
        </Transition>
      </>
    )
  }
}