import React, { Component } from 'react'
import Loader from "../../SubComponents/Loader/Loader"
import Agent from '../../../Configs/Agent'
import Header from '../../SubComponents/Header/Header'
import Banner from '../../SubComponents/Banner/Banner'
import Footer from '../../SubComponents/Footer/Footer'
import AboutSection from '../../AboutUsSections/AboutSection/AboutSection'
import CounterSection from '../../AboutUsSections/CounterSection/CounterSection'
import TestimonialSection from '../../AboutUsSections/TestimonialSection/TestimonialSection'
import OurTeamSection from '../../AboutUsSections/OurTeamSection/OurTeamSection'
class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isApiCall: true,
      dataObj: {},
      headColor: {}
    };
  }
  componentWillMount() {
    Agent.primeryRoutes.getAboutUsData().then((response) => {
      console.log(response.data);
      var resData = response.data;
      if(resData.status){
        this.setState({ dataObj: resData.data, isApiCall: false });
      }
    });
    Agent.primeryRoutes.getSettimgsData().then((response) => {
      console.log("setting data",response.data);
      var resData = response.data;
        if(resData.status){
        this.setState({ 
          headColor: resData.data.data_row,
         });
      }
    });
  }
  render() {
    const { isApiCall, dataObj} = this.state
    if(isApiCall){
      return <div  className = "loader"><div>
        {/* <h4>Turn your ideas into reality..</h4> */}
        <Loader />
        </div>
        </div>
    }
    return (
      <div>
			{/* Header Start */}
		   		<Header {...this.props}/>
			{/* Header End */}
      <div className="main-wrapper ">
        <Banner color= {this.state.headColor.highlight_color} page="about_us" title="About Us" bannerImg={dataObj.data_row.about_section_banner}/>
          {/* Section About Start */}
            {(dataObj.data_row.about_section_publish===1) && <AboutSection color= {this.state.headColor.highlight_color} data={dataObj}/> }
          {/* Section About End */}
          
          {/* section Counter Start */}
            {(dataObj.data_row.counter_section_publish===1) && <CounterSection color= {this.state.headColor.highlight_color} data={dataObj}/> }
          {/* section Counter End  */}

          {/*  Section Team Start */}
            {(dataObj.data_row.ourteam_section_publish===1) && <OurTeamSection color= {this.state.headColor.highlight_color} data={dataObj}/> }
          {/*  Section Team End */}

          {/* Section Testimonial Start */}
            {(dataObj.data_row.testimonial_section_publish===1) && <TestimonialSection color= {this.state.headColor.highlight_color} data={dataObj}/> }
          {/* Section Testimonial End */}
        
        {/* footer Start */}
				<Footer {...this.props}/>
			    {/* footer End */}
      </div>
      </div>
    
    )
  }
}

export default AboutUs;
