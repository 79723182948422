//Application Header
import React, { Component } from 'react'
import Helper from '../../../Configs/Helper'
import AOS from "aos";
import "aos/dist/aos.css";
class CtaSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration : 2000
    });
  }
  render() {
    return(
     <section className="section cta">
      <div className="container">
        <CardPopulate headColor = {this.props.color} datalist={this.props.data.data_row.cta_section_json}/>
      </div>
    </section>
    )
  }
}
function CardPopulate(props){
  let data = JSON.parse(props.datalist);
  if(Helper.objLength(data)){
    var list = data.map(function(v,k){
      return(<div key={k} className="col-lg-5 mb-15">
      <div data-aos="fade-right" className="cta-item  bg-white p-5 rounded">
        <span id ="montserrat" style={{color: `${props.headColor}`}} className="h6 text-color">{v.tagline}</span>
        <h2 id ="montserrat" className="mt-2 mb-4">{v.heading}</h2>
        <p id ="montserrat" className="lead mb-4">{v.shortdesc} </p>
        <h3 id ="montserrat"><i style={{color: `${props.headColor}`}} className="ti-mobile mr-3 text-color weight-500" />{v.mobile}</h3>
      </div>
    </div>);
    });
  }
  return <div id ="montserrat" className="row">{list} </div>
}
export default CtaSection;
