//Footer of the application
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Agent from '../../../Configs/Agent'
import ThemedAnalogClock from "themed-analog-clock";

import GeneralSettings from '../GeneralSettings/GeneralSettings'

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataRow: null,
			offices: null,
		}
	}
	componentDidMount() {
		Agent.primeryRoutes.getSettimgsData().then((response) => {
			var resData = response.data;
			  if(resData.status){
				this.setState({ 
					dataRow: resData.data.data_row,
					offices: resData.data.offices
				 });
			}
		});
	}
	render() {
		console.log("footer",this.state.dataRow);
		return (
			<footer className="footer section">
			      <div className="container">
			        <div className="row">
			          <div className="col-lg-3 col-md-6 col-sm-6">
			            <div className="widget">
			              <h4 id ="montserrat" className="text-capitalize mb-4">Corporate</h4>
			              <ul id ="montserrat" className="list-unstyled footer-menu lh-35">
			                <li><NavLink to="/terms">Terms &amp; Conditions</NavLink></li>
			                <li><NavLink to="/privacy">Privacy Policy</NavLink></li>
							<li><NavLink to="/contact-us">Contact</NavLink></li>
			              </ul>
			            </div>
			          </div>
			          
					  <div className="col-lg-3 col-md-6 col-sm-6 py-2 text-center">
			            <div  className="widget">
			              <h4 id ="montserrat" className="text-capitalize mb-4">INDIA OFFICE</h4>
						     {/* <Clock size = {110} timeFormat="24hour" hourFormat = "standard"/> */}
							{/* <AnalogClock width= "300px" theme={Themes.light} /> */}
							{/* <IndiaClock/> */}
							{this.state.offices && <span id ="montserrat">{this.state.offices['India']}</span>}
			            </div>
			          </div>
					  <div className="col-lg-3 col-md-6 col-sm-6 py-2 text-center">
			            <div  className="widget">
			              <h4 id ="montserrat" className="text-capitalize mb-4">USA OFFICE</h4>
							  {/* <ThemedAnalogClock
							  	key={1}
								size={100}
								title='The time now in India'
								timezoneName='America/New_York'
								useDarkTheme={false}
							/> */}
							{this.state.offices && <span id ="montserrat">{this.state.offices['United States']}</span>}
							{ this.state.offices!==null && this.state.offices['US']}
			            </div>
			          </div>
			          { this.state.dataRow!==null && <GeneralSettings color = {this.state.dataRow.highlight_color} screen="footer" dataRow={this.state.dataRow}/> }
			        </div>
					{ this.state.dataRow!==null && <GeneralSettings   screen="bottom_footer" dataRow={this.state.dataRow}/> }
			       
			      </div>
			    </footer>
		)
	}
}
export default Footer;
