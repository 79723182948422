import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import './index.css';
import Routes from './routes'
import Container from './container'
import * as serviceWorker from './serviceWorker';
const history = createBrowserHistory();
//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
ReactDOM.render((
    <Router history={history}>
      {/* <div className="full-x-y fixed-left-side flexbox">          
        <Switch>
          <Route path="/" name="HeftyInnovations" component={Routes} />
        </Switch>
      </div> */}
    <Container/>
    </Router>
), document.getElementById('root'))
serviceWorker.unregister();
