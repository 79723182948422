import React from "react";
import styled from "styled-components";
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./index.css"

import Routes from './routes'
import AboutUs from "./Views/Components/AboutUs/AboutUs";

function Container({ location }) {
  return (
    <Wrapper>
      <TransitionGroup component={null}>
        <CSSTransition
          key={location.key}
          timeout={{ appear: 500, enter: 500, exit: 500 }}
          classNames="dialog"
        >
          <div className="full-x-y fixed-left-side flexbox">          
            <Switch location={location}>
              <Route path="/" name="HeftyInnovations" component={Routes} />
              {/* <Route path="/about-us" name="HeftyInnovations" component={AboutUs} /> */}
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

  div.transition-group {
    position: relative;
  }

  section.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

export default withRouter(Container);
