//Application Header
import React, { Component } from 'react'
import { Link, NavLink  } from 'react-router-dom'
import Agent from '../../../Configs/Agent'
import Helper from '../../../Configs/Helper'
import { Helmet } from "react-helmet";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currRoute: this.props.location.pathname,
      dataRow: null,
      btnHover: false
    }
  }
  componentDidMount(){
    Agent.primeryRoutes.getSettimgsData().then((response) => {
			var resData = response.data;
			  if(resData.status){
				this.setState({ 
          dataRow: resData.data.data_row,
          nav: false
				 });
			}
    });
    const item = document.getElementsByClassName('header-menu-active')
    console.log(item);
    if(this.state.dataRow !==null){
      item.style.color = this.state.dataRow.highlight_color
    }
  }
  render() {
    const {dataRow,nav,btnHover} = this.state;
    // const style = {
    //   border: `1px solid ${dataRow.highlight_color}`
    // }
    if(dataRow===null){
      return null
    }
    console.log(this.state.btnHover);
    return(
        <header className="navigation">
          {dataRow!==null && <Helmet>
            <meta name="keywords" content={dataRow.seo_keyword} />
            <meta name="description" content={dataRow.seo_description}/>
          </Helmet> }
            <nav   className="navbar navbar-expand-lg py-4 nav-link-phn " id="navbar">
              <div id = "nav" className="container">
                <Link className="navbar-brand" to={'/'}> 
                { dataRow !== null ? Helper.companyName(dataRow.company_name,dataRow.base_color,dataRow.highlight_color,'header') : ''}
                </Link>

                <button style={{outline:"none"}} className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
                  {nav ? <span onClick = {() => this.setState({nav:false})} className="material-icons" style = {{color:"black"}}>clear</span> : <span onClick = {() => this.setState({nav:true})} className="material-icons" style = {{color:"black"}}>reorder</span> }
                  
                </button>
                <div className="collapse navbar-collapse text-center" id="navbarsExample09">
                  <ul className="navbar-nav ml-auto text-secondary">
                    <li className="nav-item active"><NavLink className={`nav-link text-secondary ${(this.state.currRoute==='/home' ? 'header-menu-a header-menu-active' : '' )}`} to={'/'}><b>Home</b></NavLink></li>
                    <li className="nav-item "><NavLink className={`nav-link text-secondary  ${(this.state.currRoute==='/about-us' ? 'header-menu-a header-menu-active' : '' )}`} to="/about-us"><b>About</b></NavLink ></li>
                    <li className="nav-item"><NavLink className={`nav-link text-secondary   ${(this.state.currRoute==='/services' ? 'header-menu-a header-menu-active' : '' )}`} to="/services"><b>Services</b></NavLink></li>
                    <li className="nav-item"><NavLink className={`nav-link text-secondary   ${(this.state.currRoute==='/portfolio' ? 'header-menu-a header-menu-active' : '' )}`} to="/portfolio"><b>Portfolio</b></NavLink></li>
                  </ul>
                  <form className="form-lg-inline my-2 my-md-0 ml-lg-4 text-center">
                    <NavLink style = {{border : `1px solid ${dataRow.highlight_color}`,backgroundColor: `${this.state.btnHover ? dataRow.highlight_color : "#ffffff"}`}} 
                    onMouseOver = {() => this.setState({btnHover:true})} 
                    onMouseLeave = {() => this.setState({btnHover:false})}
                    className={`btn btn-round-full text-secondary ${(this.state.currRoute==='/contact-us' ? 'header-menu-a' : '' )}`} to="/contact-us"><b>Get a Quote</b></NavLink>
                  </form>
                </div>
              </div>
            </nav>
        </header>
    )
  }
}
export default Header;
