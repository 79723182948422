import React, { Component } from 'react'
import Header from '../../SubComponents/Header/Header'
import Banner from '../../SubComponents/Banner/Banner'
import Footer from '../../SubComponents/Footer/Footer'
import Agent from '../../../Configs/Agent'
import AOS from "aos";
import "aos/dist/aos.css";
//import Helper from '../../../Configs/Helper'
import { NavLink } from 'react-router-dom'
import Loader from "../../SubComponents/Loader/Loader"
class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isApiCall: true,
      dataRow: null,
      services: null,
      headColor: {}
    };
  }
  componentDidMount(){
    AOS.init({
      // initialise with other settings
      duration : 2000
    });
  }
  async componentWillMount() {
    await Agent.primeryRoutes.getServicesData().then((response) => {
		  var resData = response.data;
	  	if(resData.status){
			this.setState({ isApiCall: false, dataRow: resData.data.data_row, services: resData.data.services });
		}
    });
    Agent.primeryRoutes.getSettimgsData().then((response) => {
      console.log("setting data",response.data);
      var resData = response.data;
        if(resData.status){
        this.setState({ 
          headColor: resData.data.data_row,
         });
      }
    });
  }
  render() {
    const { isApiCall, dataRow } = this.state
    if(isApiCall){
      return <div className = "loader">
        <div>
          {/* <h5>Quality services on time...</h5> */}
          <Loader />
          </div>
        </div>
    }
    return (
      <div>
        {/* Header Start */}
          <Header {...this.props}/>
        {/* Header End */}
        <div className="main-wrapper ">
          <Banner color = {this.state.headColor.highlight_color} page="services" title="Our services" bannerImg={dataRow.banner_image}/>
          
              {/*  Section Services Start */}
              <section className="section service border-top">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 text-center">
                      <div className="section-title">
                        {/* <span className="h6 text-color">{ dataRow.tagline }</span> */}
                        <h2 id ="montserrat" data-aos = "fade-right" className="mt-3 content-title ">{ dataRow.heading }</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <ServicesList datalist={this.state.services}/>
                  </div>
                </div>
              </section>
              {/*  Section Services End */}
              { (dataRow.footer_cta_section_publish===1) &&
                <section className="cta-2">
                <div className="container">
                  <div data-aos = "fade-up" className="cta-block p-5 rounded">
                    <div className="row justify-content-center align-items-center ">
                      <div className="col-lg-7">
                        <span id ="montserrat" style={{color:`${this.state.headColor.highlight_color}`}}>{ dataRow.footer_cta_section_tagline }</span>
                        <h2 id ="montserrat" className="mt-2 text-white">{ dataRow.footer_cta_section_heading }</h2>
                      </div>
                      <div className="col-lg-4">
                      { (dataRow.footer_cta_section_button===1) && <NavLink id ="montserrat" to="/contact-us" style={{backgroundColor:`${this.state.headColor.highlight_color}`,color:'white'}} className="btn btn-round-full weight-900 float-lg-right ">{dataRow.footer_cta_section_btntxt}</NavLink> }
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              }
              
          {/* footer Start */}
            <Footer {...this.props}/>
          {/* footer End */}
        </div>
      </div>
    
    )
  }
}
function ServicesList(props){  
  if(props.datalist!=null){
    var list = props.datalist.map(function(v,k){
      return(<div  key={k} className="col-lg-4 col-md-6 col-sm-6">
    <div  data-aos="zoom-in" className="service-item mb-5">
      <i className={v.icon} />
      <h4 id ="montserrat" className="mb-3">{v.title}</h4>
      <p id ="montserrat">{v.short_desc}</p>
    </div>
  </div>);
    });
  }
  return <div id ="montserrat" className="row justify-content-center">{list} </div>
}
export default Services;
