
import React from 'react';
// const basePath = 'http://192.168.18.5/hefty_innovations_backend/';
const basePath = 'https://heftyinnovations.com/admin/';
const baseApiPath = basePath+'api/v1/';
const baseImgPath = basePath;

function objLength(obj) {
  if (obj !== undefined || obj !== null)
    return Object.keys(obj).length;
  return 0;
}
function splitSting(string){
  if(!string) return null;

  return string.split(" ");
}
function companyName(name,baseColor,highLiightColor, screen='footer'){
  const companyName  = splitSting(name);
  const highlight = companyName[0];
  companyName.splice(0, 1);
    if(screen==='header'){
      return <><span style={{color: highLiightColor}}>HEFTY</span> <span className="txt-color-black">INNOVATIONS</span></>
    } else if(screen==='footer'){
      return <h3 className="" style={{color: highLiightColor}}>{highlight}<span className="" style={{color: baseColor}}>&nbsp;{companyName.join(' ')}</span></h3>
    }
    return null;
}
export default {
  basePath,
  baseApiPath,
  baseImgPath,
  objLength,
  splitSting,
  companyName
}
