import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Agent from '../../../Configs/Agent'
import Helper from '../../../Configs/Helper'
import Header from '../../SubComponents/Header/Header'
import Banner from '../../SubComponents/Banner/Banner'
import Footer from '../../SubComponents/Footer/Footer'
import Loader from "../../SubComponents/Loader/Loader"
import Columns from 'react-columns';
import AOS from "aos";
import "aos/dist/aos.css";

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isApiCall: true,
      dataObj: {},
      selectedCategoryId: 0,
    };
  }

  componentDidMount() {
    Agent.primeryRoutes.getPortfolioData().then((response) => {
      var resData = response.data;
      console.log(resData);
      if (resData.status) {
        this.setState({ dataObj: resData.data, isApiCall: false });
      }
    });
    AOS.init({
      // initialise with other settings
      duration: 2000
    });
  }

  onClickCategory = (event) => {
    this.setState({ selectedCategoryId: event.target.getAttribute('data-id') })
  }
  render() {
    const { isApiCall, dataObj } = this.state
    if (isApiCall) {
      return <div className="loader"><div>
        {/* <h4>Have a look at our work...</h4> */}
        <Loader />
      </div>
      </div>
    }
    return (

      <div>
        {/* Header Start */}
        <Header {...this.props} />
        {/* Header End */}
        <div className="main-wrapper ">
          <Banner page="portfolio" title="Portfolio" bannerImg={dataObj.data_row.banner_image} />
          {/* section portfolio start */}
          <section className="section portfolio pb-0 border-bottom">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-7 text-center">
                  <div className="section-title">
                    {/* <span className="h6 text-color">{dataObj.data_row.tagline}</span> */}
                    <h2 id="montserrat" data-aos="fade-right" className="mt-3 content-title ">{dataObj.data_row.heading}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid container">
              <CategoriesList datalist={dataObj.categories} onClickCategory={this.onClickCategory} selectedCategoryId={this.state.selectedCategoryId} />
              <ProjectList datalist={dataObj.projects} selectedCategoryId={this.state.selectedCategoryId} />
            </div>
          </section>

          {/* section portfolio END */}
          {/* footer Start */}
          <Footer {...this.props} />
          {/* footer End */}
        </div>
      </div>

    )
  }
}
function CategoriesList(props) {
  //a href="#" className="list-group-item list-group-item-action list-group-item-cust active"> Cras justo odio </a>
  let list = null;
  if (Helper.objLength(props.datalist)) {
    list = props.datalist.map(function (val, k) {
      return (<a key={val.id} data-id={val.id} onClick={props.onClickCategory} className={`list-group-item list-group-item-cust list-group-item-action ${(parseInt(props.selectedCategoryId) === parseInt(val.id)) ? 'active' : ''}`}>{val.name}</a>);
    });
  }
  return <div id="montserrat" className="list-group list-group-cust">
    <a key={0} data-id={0} onClick={props.onClickCategory} className={`list-group-item list-group-item-cust list-group-item-action ${(parseInt(props.selectedCategoryId) === 0) ? 'active' : ''}`}>All Products</a>
    {list}
  </div>
}
function ProjectList(props) {
  // console.log(props);
  // let Catogory_Id = parseInt(props.selectedCategoryId)
  // console.log(Catogory_Id);
  // let list = props.datalist.filter((item) => (item.category_id === Catogory_Id))
  // list.map((item, index) => (
  //   <div key={index} className="col-sm-12 w3-animate-zoom">
  //     <div className="portflio-item position-relative mb-4 ">
  //       <NavLink to="/portfolio" className="popup-gallery">
  //         <img src={item.project_image} alt="" className="img-fluid w-100" />
  //         {/* <i className="ti-plus overlay-item" /> */}
  //         <div className="portfolio-item-content">
  //           <p className="mb-0 text-white mobile-text ">{item.project_name}</p>
  //           <p className="text-white-50" style={{ padding: "0px 15px 0px 15px", textAlign: "justify" }}>{item.short_desc}</p>
  //         </div>
  //       </NavLink>
  //     </div>
  //   </div>
  // ))
  let list = null;
  if (Helper.objLength(props.datalist)) {
    list = props.datalist.map(function (v, k, index) {
      let flag = true;
      // let flag = false;
      // if (parseInt(props.selectedCategoryId) === 0) {
      //   flag = true;
      // } else if (parseInt(v.category_id) === parseInt(props.selectedCategoryId)) {
      //   flag = true;
      // }
      if (flag) {
        return (
          <div key={index}  className="col-sm-12 w3-animate-zoom">
            <div className="portflio-item position-relative mb-4 ">
              <NavLink to="/portfolio" className="popup-gallery">
                <img src={v.project_image} alt="" className="img-fluid w-100" />
                {/* <i className="ti-plus overlay-item" /> */}
                <div className="portfolio-item-content">
                  <p className="mb-0 text-white mobile-text ">{v.project_name}</p>
                  <p className="text-white-50" style={{ padding: "0px 15px 0px 15px", textAlign: "justify" }}>{v.short_desc}</p>
                </div>
              </NavLink>
            </div>
          </div>
        );
      }
    });
  }
  return <div id="montserrat" className="row portfolio-gallery w3-container w3-center w3-animate-right"><Columns columns="4">{list}</Columns></div>
}
export default Portfolio;
