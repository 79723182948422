//Application Header
import React, { Component } from 'react'
import Helper from '../../../Configs/Helper'
import AOS from "aos";
import "aos/dist/aos.css";
class OurServicesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  componentDidMount(){
    AOS.init({
      // initialise with other settings
      duration : 2000
    });
  }
  render() {
    return(
      <section className="section service border-top pb-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 text-center">
            <div className="section-title">
              <span data-aos="fade-left" id ="montserrat" style = {{color:`${this.props.color}`}} className="h6 text-color">{this.props.data.data_row.ourservices_section_tagline}</span>
              <h2 data-aos="fade-right" id ="montserrat" className="mt-3 content-title">{this.props.data.data_row.ourservices_section_heading}</h2>
            </div>
          </div>
        </div>
        <ServicesList datalist={this.props.data.services}/>
      </div>
    </section>
    )
  }
}
function ServicesList(props){  
  if(Helper.objLength(props.datalist)){
    var list = props.datalist.map(function(v,k){
      return(<div key={k} className="col-lg-4 col-md-6 col-sm-6">
      <div data-aos="fade-up" className="service-item mb-5">
        <i className={v.icon} />
        <h4 id ="montserrat" className="mb-3 weight-500">{v.title}</h4>
        <p id ="montserrat">{v.short_desc}r</p>
      </div>
    </div>);
    });
  }
  return <div id ="montserrat" className="row justify-content-center">{list} </div>
}
export default OurServicesSection;
