import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Header from '../../SubComponents/Header/Header'
//import Banner from '../../SubComponents/Banner/Banner'
import Footer from '../../SubComponents/Footer/Footer'
import Agent from '../../../Configs/Agent'
import Helper from '../../../Configs/Helper'
import Loader from "../../SubComponents/Loader/Loader"
class MemberProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isApiCall: true,
      dataRow: null,
    };
  }
   componentWillMount() {
    Agent.primeryRoutes.getMemberProfile(this.props.match.params.id).then((response) => {
      var resData = response.data;
			  if(resData.status){
          this.setState({ 
            isApiCall: false,
            dataRow: resData.data.data_row
          },()=>window.executeHome());
        }
    });
    
  }
  
  render() {
    const { isApiCall, dataRow } = this.state;
    if(isApiCall){
      return <div style={{backgroundColor: `${this.state.headColor.highlight_color}`}} className = "loader"><div><Loader /></div></div>
    }
    return (
      <div>
        {/* Header Start */}
          <Header {...this.props}/>
        {/* Header End */}
        <div className="main-wrapper ">
          {/* <Banner page="services" title={dataRow.name}/> */}
        <section className="section about-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6" >
                <div className="about-item pr-3 mb-5 mb-lg-0">
                  <span className="h6 text-color">About Me</span>
                  <h2 className="mt-3 mb-4 position-relative content-title">{dataRow.name}</h2>
                  <p className="mb-5" dangerouslySetInnerHTML={{__html: dataRow.professional_profile}} />
                  <table className="table">
                    <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{dataRow.name}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{dataRow.address}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{dataRow.email}</td>
                    </tr>
                    <tr style={{borderBottom: "1px solid #dee2e6"}}>
                      <td>Phone</td>
                      <td>{dataRow.phone}</td>
                    </tr>
                    </tbody>
                  </table>
                  
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="about-item-img text-center">
                  <img src={dataRow.pic} alt="" className="img-fluid" style={{ maxHeight: "420px"}}/>
                </div>
              </div>
            </div>
          </div>
        </section>
         {/*  Section Education Details Start */}
         <section className="section sect-profile" style={{padding: "35px 0 0 0"}}>
            <div className="container border-top">
              <div className="row justify-content-center" style={{marginTop: "20px"}}>
                <div className="col-lg-7 text-center">
                  <div className="section-title">
                    {/* <span className="h6 text-color">Education</span> */}
                    <h2 className="mt-3 content-title ">Education</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <EducationInfo datalist={dataRow.education}/>
              </div>
            </div>
          </section>
          {/*  Section Education Details End */}

          {/*  Section Employement History Start */}
          <section className="section sect-profile" style={{padding: "0px 0 0 0"}}>
            <div className="container border-top">
              <div className="row justify-content-center" style={{marginTop: "20px"}}>
                <div className="col-lg-7 text-center">
                  <div className="section-title">
                    {/* <span className="h6 text-color">Employement History</span> */}
                    <h2 className="mt-3 content-title ">Employement History</h2>
                  </div>
                </div>
              </div>
              <div className="row">
              <EmployementHistory datalist={dataRow.emp_history}/>
              </div>
            </div>
          </section>
          {/*  Section Employement History End */}
          {/*  Section Skills Start */}
          <section className="section sect-profile" style={{padding: "0px 0px 40px 0px"}}>
            <div className="container border-top">
              <div className="row justify-content-center" style={{marginTop: "20px"}}> 
                <div className="col-lg-7 text-center">
                  <div className="section-title">
                    {/* <span className="h6 text-color">Skills</span> */}
                    <h2 className="mt-3 content-title ">Skills</h2>
                  </div>
                </div>
              </div>
              <div className="row">
              <Skills datalist={dataRow.expertise}/>
              </div>
            </div>
          </section>
          {/*  Section Skills End */}
          <section className="section cta">
                <div className="container">
                    <div className="row justify-content-center align-items-center ">
                      <div className="col-sm-12 text-center">
                        <h2 className="mt-2 text-white"> I'm <span className="text-color">Available</span> for Hiring</h2>
                        <p style={{color: '#fff'}}>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                      </div>
                      <div className="col-sm-12 text-center">
                      <NavLink to="/contact-us" className="btn btn-main btn-round-full">Hire Me</NavLink> 
                      </div>
                    </div>
                </div>
              </section>
    {/* footer Start */}
    <Footer {...this.props}/>
          {/* footer End */}
        </div>
        </div>
    )
  }
}

function EducationInfo(props){
  let data = props.datalist;
  if(Helper.objLength(data)){
    var list = data.map(function(v,k){
      return (
        <div key={k} className="col-lg-12 col-md-12 col-sm-12">
          <div className="service-item mb-5">
            <i className="ti-medall" />
            <h4 className="mb-3">{v.degree_name}</h4>
            <span className="edu-year">{v.year}</span>
            <p>{v.university}</p>
          </div>
        </div>
      )
    });
  }
  return <>{list} </>
}

function EmployementHistory(props){
  let data = props.datalist;
  if(Helper.objLength(data)){
    var list = data.map(function(v,k){
      return (
        <div key={k} className="col-lg-12 col-md-12 col-sm-12">
        <div className="service-item mb-5">
          <i className="ti-alarm-clock" />
          <h4 className="mb-3">{v.heading}</h4>
          <span className="">{v.subheading}</span>
          <p dangerouslySetInnerHTML={{__html: v.description}} />
        </div>
      </div>
      )
    });
  }
  return <>{list} </>
}

function Skills(props){
  let data = props.datalist;
  let list = [];
  if(data!=='' && data!==null){
    let perc = 90;
    const expertiseArr = data.split(',');
    for(let i=0; i<expertiseArr.length; i++){
      perc = Math.random() * (92 - 70)+70;
      list.push(<div key={i} className="col-md-6 animate-box">
      <div className="progress-wrap ftco-animate">
        <h3>{expertiseArr[i]}</h3>
        <div className="progress">
          <div className="progress-bar color-1" role="progressbar" aria-valuenow={perc} aria-valuemin={0} aria-valuemax={100} style={{width: perc+'%'}}>
            <span>{perc}%</span>
          </div>
        </div>
      </div>
    </div>);
    }
  }
  return list;

}
export default MemberProfile;
