import React, { Component } from 'react'
import Header from '../../SubComponents/Header/Header'
import Banner from '../../SubComponents/Banner/Banner'
import Footer from '../../SubComponents/Footer/Footer'
import Agent from '../../../Configs/Agent'
//import Helper from '../../../Configs/Helper'
import Loader from "../../SubComponents/Loader/Loader"
class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isApiCall: true,
      dataRow: null,
    };
  }
  async componentWillMount() {
    await Agent.primeryRoutes.getSettimgsData().then((response) => {
			var resData = response.data;
			  if(resData.status){
				this.setState({ 
          isApiCall: false,
					dataRow: resData.data.data_row
				 });
			}
		});
  }
  render() {
    const { isApiCall, dataRow } = this.state
    if(isApiCall){
      return <div className = "loader"><div><Loader /></div></div>
    }
    return (
      <div>
        {/* Header Start */}
          <Header {...this.props}/>
        {/* Header End */}
        <div className="main-wrapper ">
          <Banner page="privacy_policy" title="Privacy Policy"/>
          
              <section className="section service border-top">
                <div className="container border-bottom">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 text-center">
                      <div className="section-title">
                        {/* <span className="h6 text-color">{ dataRow.tagline }</span> */}
                        <h2 className="mt-3 content-title ">Privacy Policy</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div dangerouslySetInnerHTML={{__html: dataRow.privacy_policy}} />
                  </div>
                </div>
              </section>
              
          {/* footer Start */}
            <Footer {...this.props}/>
          {/* footer End */}
        </div>
      </div>
    
    )
  }
}
export default PrivacyPolicy;
