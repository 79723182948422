//Application Header
import React, { Component } from 'react'
import Helper from '../../../Configs/Helper'
import AOS from "aos";
import "aos/dist/aos.css";
class IntroSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  componentDidMount(){
    AOS.init({
      // initialise with other settings
      duration : 2000
    });
  }
  render() {
    console.log(this.props.data.data_row.intro_section_tagline);
    return(
       <section className="section intro">
       <div className="container">
         <div className="row ">
           <div className="col-lg-8">
             <div className="section-title">
               <span data-aos="fade-right" data-aos="fade-down" id ="montserrat" style={{color: `${this.props.color}`}} className="h6 text-color ">{this.props.data.data_row.intro_section_tagline}</span>
               <h2 data-aos="fade-right" id ="montserrat" className="mt-3 content-title">{this.props.data.data_row.intro_section_heading} </h2>
             </div>
           </div>
         </div>
         <FeaturesPopulate features={this.props.data.data_row.intro_section_features}/>
       </div>
     </section>
    )
  }
}
function FeaturesPopulate(props){
  let features = JSON.parse(props.features);
  if(Helper.objLength(features)){
    var fList = features.map(function(v,k){
      return(<div key={k} className="col-lg-4 col-md-6 col-12">
      <div className="intro-item mb-5 mb-lg-0"> 
        <i className={`color-one ${v.icon}`} />
        <h4 id ="montserrat" className="mt-4 mb-3">{v.title}</h4>
        <p id ="montserrat">{v.short_desc}</p>
      </div>
    </div>);
    });
  }
  return <div id ="montserrat" className="row justify-content-center">{fList} </div>
}
export default IntroSection;
